import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import {
  DataGridPremium,
  GridColDef,
  useGridApiContext,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridRowParams,
  GridRowsProp,
  GridRowId,
  GridCallbackDetails,
  DataGridPremiumProps,
  GridToolbar,
  GridCellSelectionModel,
  GridRowModelUpdate,
  useGridApiRef,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';

import { Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Link, styled } from '@mui/material';
import { Height } from '@mui/icons-material';
import { height } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import SignedPDF from './SignedPDF';

interface OrderSummaryDetailsProps {
  status: string | undefined;
  fromDate: string
  toDate: string
  orderNumber: string
}


function DetailPanelContent({ row: rowProp }: { row: any }) {
  const apiRef = useGridApiContext();
  const navigate = useNavigate();
  const tokenValue: any = localStorage.getItem("accessToken")
  const apiUrlOrder: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/getDashData_orders"
  const apiUrlItemsOrder: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/GetOrderItems"
  const apiUrlItemsOrderItems: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/GetItemDetails"
  const [open, setOpen] = React.useState(false);
  const [orderItemsData, setorderItemsData] = React.useState([])
  const [orderItemsDataDetails, setorderItemsDataDetails] = React.useState([])
  const [orderItemsDataRows, setorderItemsDataRows] = React.useState([])
  const [width, setWidth] = React.useState(() => {
    const dimensions = apiRef.current.getRootDimensions();
    return dimensions.viewportInnerSize.width;
  });

  const handleViewportInnerSizeChange = React.useCallback(() => {
    const dimensions = apiRef.current.getRootDimensions();
    setWidth(dimensions.viewportInnerSize.width);
  }, [apiRef]);


  const currCustomer = localStorage.getItem("currentOwner")
  const currWHS = localStorage.getItem("currentWHS")



  React.useEffect(() => {

    createMasterData(rowProp.OrderNumber)
    return apiRef.current.subscribeEvent(
      'viewportInnerSizeChange',
      handleViewportInnerSizeChange,

    );
  }, [apiRef, handleViewportInnerSizeChange]);

  async function getOrderItemsApiData(params: any) {
    const responseOrdersItems = await getDashData_ordersItems(
      {
        "orderNo": params,
        "custCode": currCustomer,
        "whseCode": currWHS
      })
    if (responseOrdersItems) {
      setorderItemsData(responseOrdersItems?.Payload)
    }
  }

  async function getDashData_ordersItems(params: any) {
    return fetch(apiUrlItemsOrder, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(params),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
 navigate("/login");
 window.location.reload();
      })
  }

  const createMasterData = (rowprops: any) => {
    let dataItems: Array<any> = []
    getOrderItemsApiData(rowProp.OrderNumber)

  }



  async function getOrderItemsDetailsApiData(params: any) {
    const responseOrdersItems = await getDashData_ordersItemsDetails(
      {
        "itemNo": params,
        "custCode": currCustomer,
        "whseCode": currWHS

      })
    if (responseOrdersItems) {
      setorderItemsDataDetails(responseOrdersItems?.Payload)
    }
  }

  async function getDashData_ordersItemsDetails(params: any) {
    return fetch(apiUrlItemsOrderItems, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(params),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      })
  }
  const handleClose = () => {
    setOpen(false);
  };
  const createItemsData = (rowprops: any) => {
    let dataItems: Array<any> = []
    getOrderItemsDetailsApiData(rowprops.row.ItemNo)
    setOpen(true)
    return orderItemsDataDetails?.map((c: any, index: any) => ({
      id: index,
      Item: c.Item,
      Description: c.Description,
      Pack: c.Pack,
      GrossWeight: c.GrossWeight
    }))
  }



  const columnsItem: GridColDef[] = [
    { field: 'OrderKey', headerName: 'Order Number' },
    { field: 'orderLineNo', headerName: 'order Line No' },
    // { field: 'OrderDetailID', headerName: 'Order Detail ID' },
    { field: 'ItemNo', headerName: 'Item No' },

    { field: 'ItemDescription', headerName: 'Item Description' },
    { field: 'ORIGINALQTY', headerName: 'ORIGINAL QTY' },
    { field: 'OPENQTY', headerName: 'OPEN QTY' },
    { field: 'SHIPPEDQTY', headerName: 'SHIPPED QTY' },
    { field: 'ADJUSTEDQTY', headerName: 'ADJUSTED QTY' },
    { field: 'QTYALLOCATED', headerName: 'QTY ALLOCATED' },

    { field: 'QTYPICKED', headerName: 'QTY PICKED' },
    { field: 'UOM', headerName: 'UOM' },
    { field: 'PACKKEY', headerName: 'PACK KEY' },
    { field: 'PRODUCT_WEIGHT', headerName: 'PRODUCT WEIGHT' },
    { field: 'PRODUCT_CUBE', headerName: 'PRODUCT CUBE' },
    { field: 'ACTUALSHIPDATE', headerName: 'ACTUAL SHIPDATE' },
    { field: 'SPSTrackingNumber', headerName: 'SPS Tracking Number' },
    

    // { field: 'BUYERPO', headerName: 'BUY ERPO' },
    // { field: 'FULFILLQTY', headerName: 'FULFILL QTY' },
    // { field: 'PROCESSEDQTY', headerName: 'PROCESSED QTY' },
    // { field: 'QTYTOPROCESS', headerName: 'QTY TO PROCESS' },

  ];
  const rowItems: GridRowsProp = orderItemsData.map((c: any, index) => ({
    id: index,
    Owner: c.Owner,
    WHSEID: c.WHSEID,
    OrderKey: c.OrderKey,
    orderLineNo: c.orderLineNo,
    OrderDetailID: c.OrderDetailID,
    ItemNo: c.ItemNo,
    ItemDescription: c.ItemDescription,
    ORIGINALQTY: c.ORIGINALQTY,
    OPENQTY: c.OPENQTY,
    SHIPPEDQTY: c.SHIPPEDQTY,
    ADJUSTEDQTY: c.ADJUSTEDQTY,
    QTYALLOCATED: c.QTYALLOCATED,
    QTYPICKED: c.QTYPICKED,
    UOM: c.UOM,
    PACKKEY: c.PACKKEY,
    PRODUCT_WEIGHT: c.PRODUCT_WEIGHT,
    PRODUCT_CUBE: c.PRODUCT_CUBE,
    ACTUALSHIPDATE: c.ACTUALSHIPDATE,
    BUYERPO: c.BUYERPO,
    FULFILLQTY: c.FULFILLQTY,
    PROCESSEDQTY: c.PROCESSEDQTY,
    QTYTOPROCESS: c.QTYTOPROCESS,
    SPSTrackingNumber :c.SPSTrackingNumber
  }))

  const columnsItemDetails: GridColDef[] = [
    { field: 'Item', headerName: 'Item' },
    { field: 'Description', headerName: 'Description' },
    { field: 'Pack', headerName: 'Pack' },
    { field: 'GrossWeight', headerName: 'Gross Weight' },

    { field: 'NetWeight', headerName: 'Net Weight' },
    { field: 'Cube', headerName: 'Cube' },
    { field: 'Class', headerName: 'Class' },
    { field: 'ItemGroup1', headerName: 'Item Group1' },
    { field: 'PICKCODE', headerName: 'PICK CODE' },
    { field: 'CartonGroup', headerName: 'Carton Group' },

    { field: 'PUTCODE', headerName: 'PUT CODE' },
    { field: 'PutAwayLocation', headerName: 'Put Away Location' },
    { field: 'PutAwayZone', headerName: 'Put Away Zone' },
    { field: 'DateItemLastCounted', headerName: 'Date Item Last Counted' },
    { field: 'InboundQCLocation', headerName: 'Inbound QC Location' },
    { field: 'RotateBy', headerName: 'RotateBy' },

    { field: 'RFDefaultReceivingPack', headerName: 'RF Default Receiving Pack' },
    { field: 'RFDefaultReceivingUOM', headerName: 'RF Default Receiving UOM' },
    { field: 'ReturnLocation', headerName: 'Return Location' },
    { field: 'InboundQCLoc', headerName: 'Inbound QCLoc' },
    { field: 'ReceiptValidation', headerName: 'Receipt Validation' },
    { field: 'OutboundQCLoc', headerName: 'Outbound QC Loc' },
    

  ];

  const rowItemsDetails: GridRowsProp = orderItemsDataDetails.map((c: any, index) => ({
    id: index,
    Owner: c.Owner,
    Warehouse: c.Warehouse,
    Item: c.Item,
    Description: c.Description,
    Pack: c.Pack,
    GrossWeight: c.GrossWeight,
    NetWeight: c.NetWeight,
    Cube: c.Cube,
    Class: c.Class,
    ItemGroup1: c.ItemGroup1,
    PICKCODE: c.PICKCODE,
    CartonGroup: c.CartonGroup,
    PUTCODE: c.PUTCODE,
    PutAwayLocation: c.PutAwayLocation,
    PutAwayZone: c.PutAwayZone,
    DateItemLastCounted: c.DateItemLastCounted,
    InboundQCLocation: c.InboundQCLocation,
    RotateBy: c.RotateBy,
    RFDefaultReceivingPack: c.RFDefaultReceivingPack,
    RFDefaultReceivingUOM: c.RFDefaultReceivingUOM,
    ReturnLocation: c.ReturnLocation,
    InboundQCLoc: c.InboundQCLoc,
    ReceiptValidation: c.ReceiptValidation,
    OutboundQCLoc: c.OutboundQCLoc
  }))

  const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) => row.hierarchy;


  return (
    <><Stack
      sx={{
        py: 2,
        height: '100%',
        boxSizing: 'border-box',
        position: 'sticky',
        left: 0,
        width,
      }}
      direction="column"
    >
      <Paper
        sx={{
          flex: 1,
          mx: "auto",
          width: "90%",
          height: "1000px",
          p: 1,
          background: "#f6f1f4",
        }}
      >
        <Stack direction="column" spacing={1} sx={{ height: 1 }}>
          <Typography className='colordivSumHead' fontWeight="bold" variant="h6">{`Details for order: #${rowProp.OrderNumber}`}</Typography>
          <Box sx={{ width: '100%', height: 300 }}>
            <DataGridPremium
              density="compact"
              slots={{ toolbar: GridToolbar }}
              rowSelection={true}
              onRowClick={createItemsData}
              columns={columnsItem}
              rows={rowItems}
              sx={{ flex: 1 }}
              hideFooter />
          </Box>
        </Stack>
      </Paper>


    </Stack>

      <Box
        sx={{ width: '100%', height: '200px' }}>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          style={
            {
              height: '100%', width: '100%', alignItems: 'center'
            }}
        >
          <DialogTitle className='colordivSumHead font-bold' id="form-dialog-title">Item Properties:</DialogTitle>
          <DialogContent>
            <DialogContentText>

            </DialogContentText>
            <Box sx={{ width: '100%', height: 120 }}>
              <DataGridPremium

                columns={columnsItemDetails}
                rows={rowItemsDetails}

                density="compact"
                hideFooter
              />
            </Box>

          </DialogContent>
        </Dialog>
      </Box></>

  );
}

const columns: GridColDef[] = [
  { field: 'OrderNumber', headerClassName: "headingbg-color", headerName: 'IGD Order' },
  { field: 'ExternalOrderNo', headerClassName: "headingbg-color", headerName: 'Customer Order' },
  { field: 'PurchaseOrder', headerClassName: "headingbg-color", headerName: 'Purchase Order' },
  { field: 'orderStatus', headerClassName: "headingbg-color", headerName: 'Order status' },
  { field: 'orderType', headerClassName: "headingbg-color", headerName: 'Order Type' },
  { field: 'OrderRcvdDate', headerClassName: "headingbg-color", headerName: 'Order Received' }, 
  { field: 'OrderDate', headerClassName: "headingbg-color", headerName: 'PO Date' },
  { field: 'ActualShipDate', width: 150, headerClassName: "headingbg-color", headerName: 'Actual Ship Date' },
  { field: 'RequestedShipDate', width: 150, headerClassName: "headingbg-color", headerName: 'Requested Ship Date' },
  { field: 'ScheduledShipDate', width: 150, headerClassName: "headingbg-color", headerName: 'Scheduled Ship Date' },
 
  {
    field: 'BillTo',
    renderCell: (params) => (
      <Link href="#">{params.value!.toString()}</Link>
    ),
    headerClassName: "headingbg-color", headerName: 'Bill To'
  },
  {
    field: 'ShipTo',
    renderCell: (params) => (
      <Link href="#">{params.value!.toString()}</Link>
    ),
    headerClassName: "headingbg-color", headerName: 'Ship To'
  },
  { field: 'Priority', headerClassName: "headingbg-color", headerName: 'Priority' },
  { field: 'CarrierCode', headerClassName: "headingbg-color", headerName: 'SCAC' },
  { field: 'CarrierName', headerClassName: "headingbg-color", headerName: 'Carrier Name' },
 

  { field: 'ConsigneeAddress', width: 150, headerClassName: "headingbg-color", headerName: 'Consignee Address' },
  { field: 'TotalOrdered', headerClassName: "headingbg-color", headerName: 'Quantity Ordered' },
  { field: 'PiecesOrderd', headerClassName: "headingbg-color", headerName: 'Pieces Ordered' },
  { field: 'TotalShipped', headerClassName: "headingbg-color", headerName: 'Quantity Shipped' },
  { field: 'PiecesShipped', headerClassName: "headingbg-color", headerName: 'Pieces Shipped' },
 { field: 'TotalOrderLines', headerClassName: "headingbg-color", headerName: 'Order Lines' },
 { field: 'AppointmentNumber', width: 150, headerClassName: "headingbg-color", headerName: 'Appointment Number' },
 { field: 'Door', headerClassName: "headingbg-color", headerName: 'Door' },
 { field: 'LoadNumber', headerClassName: "headingbg-color", headerName: 'Load Number' },
 { field: 'TrailerNumber', headerClassName: "headingbg-color", headerName: 'Trailer Number' },
 {
  field: 'BOLNumber',
  width: 150,
  renderCell: (params) => {
      if(params.row.signedBOLFile || params.row.sourceBOLFile) {
          return <Link href="#">{params.value && params.value!.toString()}</Link>
      } else {
          return params.value && params.value.toString()
      }
      
  },
  headerClassName: "headingbg-color", 
  headerName: 'BOL Number'
},
  { field: 'tracking_PROnumber', width: 150, headerClassName: "headingbg-color", headerName: 'Tracking/PRO' },
  
  
  // { field: 'Route',headerClassName:"headingbg-color" , headerName: 'Route' },
  // { field: 'Stop',headerClassName:"headingbg-color" , headerName: 'Stop' },

]
const FullWidthDetailPanel: React.FC<OrderSummaryDetailsProps> = (props) => {
  const [orderNo, setOrderNo] = React.useState('');
  const [isSigned, setIsSigned] = React.useState(0);
  const [signedFileName, setsignedFileName] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const [orderData, setOrderData] = React.useState(new Array)
  const [orderDataBillShipTo, setOrderDataBillShipTo] = React.useState([])
  const [orderItemsData, setorderItemsData] = React.useState([])
  const getDetailPanelContent = React.useCallback(
    ({ row }: GridRowParams) => <DetailPanelContent row={row} />,
    [],
  );

  const apiRef = useGridApiRef();
  const [cellSelectionModel, setCellSelectionModel] =
    React.useState<GridCellSelectionModel>({});

    const [sortModel, setSortModel] = React.useState([
      {
        field: 'ActualShipDate', // specify the field to sort  
        sort: 'desc', // specify the sort direction 
      },
    ]);


  const [gridValidRowModel, setGridValidRowModel] =
    React.useState<GridValidRowModel>({});



  const [openPopUp, setOpenPopUp] = React.useState(false)
  const getDetailPanelHeight = React.useCallback(() => 200, []);
  const navigate = useNavigate();

  const tokenValue: any = localStorage.getItem("accessToken")
  const apiUrlOrder: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/getDashData_orders"
  const apiUrlItemsOrder: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/GetOrderItems"
  const apiUrlBillToShipTo: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/GetOrderBillToShipToDtls"


  React.useEffect(() => {

    try {
      if (currCustomer) {
        getOrdersApiData("")
      }
    }
    catch (e) {
      //log error
    }
  },
    [props]);

  const currCustomer = localStorage.getItem("currentOwner")
  const currWHS = localStorage.getItem("currentWHS")


  async function getBillToShipToDetails(params: any) {
    const responseOrders = await getBillToShipTo_Details(
      {
        "custCode": currCustomer,
        "whseCode": currWHS,
        "orderNo": params
      })

    if (responseOrders) {
      setOrderDataBillShipTo(responseOrders.Payload)
    }
  }

  async function getBillToShipTo_Details(params: any) {
    return fetch(apiUrlBillToShipTo, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(params),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      })
  }


  async function getOrdersApiData(params: any) {
    const responseOrders = await getDashData_orders(
      {
        "orderStatus": props.status,
        "fromDate": props.fromDate,
        "toDate": props.toDate,
        "custCode": currCustomer,
        "whseCode": currWHS
      })

    if (responseOrders) {
      
      if (props.orderNumber) {       
        const  filteredRecords =  responseOrders.Payload?.find((x: any) => x.OrderNumber == props.orderNumber);
         if(filteredRecords)
             {
               const newArrayList = new Array(filteredRecords)
               setOrderData(newArrayList)
             }        
       }
       else {
        setOrderData(responseOrders.Payload)
       }

      
    }
  }

  async function getDashData_orders(params: any) {
    return fetch(apiUrlOrder, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(params),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      })
  }
  async function getDashData_ordersItems(params: any) {
    return fetch(apiUrlItemsOrder, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(params),
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
 navigate("/login");
 window.location.reload();
      })
  }

  async function getOrderItemsApiData(params: any) {
    const responseOrdersItems = await getDashData_ordersItems(
      {
        "orderNo": params,
        "custCode": currCustomer,
        "whseCode": currWHS
      })
    return responseOrdersItems
  }

  const rows: GridRowsProp = orderData?.map((c: any, index) => ({
    id: index,
    OrderNumber: c.OrderNumber,
    Owner: c.Owner,
    orderType: c.orderType,
    WHSEID: c.WHSEID,
    OrderRcvdDate:c.OrderRcvdDate,
    OrderDate: c.OrderDate,
    orderStatus: c.orderStatus,
    BillTo: c.BillTo,
    ShipTo: c.ShipTo,
    Priority: c.Priority,
    CarrierCode: c.CarrierCode,
    CarrierName: c.CarrierName,
    ExternalOrderNo: c.ExternalOrderNo,
   
    RequestedShipDate: c.RequestedShipDate,
    ScheduledShipDate: c.ScheduledShipDate,
    ConsigneeAddress: c.ConsigneeAddress,
    TotalOrdered: c.TotalOrdered,
    TotalShipped :c.TotalShipped,
    ActualShipDate: c.ActualShipDate,
    TotalOrderLines: c.TotalOrderLines,
    PurchaseOrder: c.PurchaseOrder,
    tracking_PROnumber: c.tracking_PROnumber,
    AppointmentNumber: c.AppointmentNumber,
    Door: c.Door,
    // Route : c.Route,
    // Stop : c.Stop,
    LoadNumber: c.LoadNumber,
    TrailerNumber: c.TrailerNumber,
    BOLNumber: c.BOLNumber,
    signedBOLFile: c.signedBOLFile,
    sourceBOLFile : c.sourceBOLFile,
    isBOLSigned: c.isBOLSigned,
    PiecesOrderd: c.PiecesOrderd,
    PiecesShipped: c.PiecesShipped
  }));

  const handleClose = () => {
    setOpenPopUp(false);
  };

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    (newIds: GridRowId[], details: GridCallbackDetails) => {

      setDetailPanelExpandedRowIds(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
      );

    },
    [],
  );

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<
    GridRowId[]
  >([]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: "none",
    background: "transparent",
  }));

  const handleCellSelectionModelChange = React.useCallback(
    (newModel: GridCellSelectionModel) => {

      Object.entries(newModel).forEach(([id, fields]) => {
        const updatedRow = apiRef.current.getRow(id);
        if (fields.BillTo || fields.ShipTo) {
          
          getBillToShipToDetails(updatedRow.OrderNumber)
          setOpenPopUp(true)
          setGridValidRowModel(updatedRow)
        } else if(fields.BOLNumber) {
          setOrderNo(updatedRow.OrderNumber)
          setIsSigned(updatedRow.isBOLSigned)
          const filepath = "https://igdstg.igdistribution.net/igdcpapi/"
          setsignedFileName(filepath + updatedRow.signedBOLFile);
          if (updatedRow.isBOLSigned) {
            setOpen(true)
          
          } else {
            if(updatedRow.sourceBOLFile) {
                setsignedFileName(filepath + updatedRow.sourceBOLFile)
                setOpen(true)
            }
          }
          setGridValidRowModel(updatedRow);
        }
      })

      // setCellSelectionModel(newModel);
    },
    [],
  );

  //   const updateSelectedCells = React.useCallback(() => {
  //     const updates: GridRowModelUpdate[] = [];

  //     Object.entries(cellSelectionModel).forEach(([id, fields]) => {
  //      // const updatedRow = { ...apiRef.current.getRow(id) };
  // debugger
  //       Object.entries(fields).forEach(([field, isSelected]) => {
  //         if (isSelected) {
  //         //  updatedRow[field] = value;
  //         }
  //       });

  //      // updates.push(updatedRow);
  //     });

  //     //apiRef.current.updateRows(updates);
  //   }, [ cellSelectionModel]);

  return (
    <><Box sx={{ width: '100%', height: 560, marginBottom: '10px' }}>
      <div className='mt-16'></div>
      <Grid className='mt-8'>
        <Grid item sm={12}>
          <h6 className='colordivSumHead'>Order Details:</h6>
          <Item>
            <div style={{ height: 550, width: '100%' }}>
              <DataGridPremium
                rowSelection={false}
                apiRef={apiRef}
                cellSelectionModel={cellSelectionModel}
                cellSelection={true}
                columns={columns}
                rows={rows}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'ActualShipDate', sort: 'desc' }],
                  },
                }}               
                density="compact"
                onCellSelectionModelChange={handleCellSelectionModelChange}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  }
                }}
                // pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                // onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                // detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                sx={{
                  '& .MuiDataGrid-detailPanel': {
                    overflow: 'visible',
                  },
                }} />
            </div>
          </Item>
        </Grid>
      </Grid>

    </Box>
    <Box
      sx={{ width: '100%', height: '200px' }}>

        <Dialog
          open={openPopUp}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          style={{
            height: '100%', width: '100%', alignItems: 'center'
          }}
        >
          <DialogTitle className='colordivSumHead font-bold' id="form-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText>

            </DialogContentText>
            <Box sx={{ width: 800, height: 450 }}>
              {orderDataBillShipTo?.map((s: any) => {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <table
                        style={{
                          width: "100%",
                          marginBottom: "20px",
                          tableLayout: "fixed",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              className="colordivSumHead font-bold"
                              style={{ textAlign: "left" }}
                            >
                              Bill To Details :
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-bold table-align table-width">
                              Order Number
                            </td>
                            <td className="table-align">{s.OrderNumber}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              {" "}
                              BillTo Name
                            </td>
                            <td className="table-align">{s.BillTo_Name}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              {" "}
                              BillTo Address
                            </td>
                            <td className="table-align">{s.BillTo_Address}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              {" "}
                              BillTo City
                            </td>
                            <td className="table-align">{s.BillTo_City}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              BillTo State
                            </td>
                            <td className="table-align">{s.BillTo_State}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              BillTo Country
                            </td>
                            <td className="table-align">{s.BillTo_Country}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              BillTo ZipCode
                            </td>
                            <td className="table-align">{s.BillTo_ZipCode}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              BillTo PhoneNo
                            </td>
                            <td className="table-align">{s.BillTo_PhoneNo}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              BillTo Contact
                            </td>
                            <td className="table-align">{s.BillTo_Contact}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              BillTo VAT
                            </td>
                            <td className="table-align">{s.BillTo_VAT}</td>
                          </tr>
                        </tbody>
                      </table>

                      <table style={{ width: "100%", tableLayout: "fixed" }}>
                        <thead>
                          <tr>
                            <th
                              className="colordivSumHead font-bold"
                              style={{ textAlign: "left" }}
                            >
                              Ship To Details :
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-bold table-align table-width">
                              Order Number
                            </td>
                            <td className="table-align">{s.OrderNumber}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              ShipTo Name
                            </td>
                            <td className="table-align">{s.ShipTo_Name}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              ShipTo Address
                            </td>
                            <td className="table-align">{s.ShipTo_Address}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              ShipTo City
                            </td>
                            <td className="table-align">{s.ShipTo_City}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              ShipTo State
                            </td>
                            <td className="table-align">{s.ShipTo_State}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              ShipTo Country
                            </td>
                            <td className="table-align">{s.ShipTo_Country}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              ShipTo ZipCode
                            </td>
                            <td className="table-align">{s.ShipTo_ZipCode}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              ShipTo PhoneNo
                            </td>
                            <td className="table-align">{s.ShipTo_PhoneNo}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              ShipTo Contact
                            </td>
                            <td className="table-align">{s.ShipTo_Contact}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              ShipTo Fax
                            </td>
                            <td className="table-align">{s.ShipTo_Fax}</td>
                          </tr>
                          <tr>
                            <td className="font-bold table-align table-width">
                              ShipTo VAT
                            </td>
                            <td className="table-align">{s.ShipTo_VAT}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                );
              })}
            </Box>

          </DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>
      </Box>
      {open && (
                // <Box sx={{ width: '100%', height: '800px' }}>

                    <Dialog

                        id='signDialog'
                        open={open}
                        onClose={() => setOpen(false)}
                        aria-labelledby="form-dialog-title"
                        style={
                            {
                                height: '100%', width: '100%', alignItems: 'left'
                            }}
                    >
                        <DialogTitle className='colordivSumHead font-bold' id="form-dialog-title">Order No: {orderNo}</DialogTitle>
                        <DialogContent>
                            <SignedPDF
                                fileName={signedFileName}
                                action={handleClose}
                                isSigned={isSigned}
                                showSign={false}
                                orderNo = {orderNo}
                            ></SignedPDF>
                        </DialogContent>
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpen(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Dialog>
                // </Box>
            )
            }
      </>
  );
}

export default FullWidthDetailPanel
