import React, { useRef, useState } from 'react';
import { saveAs } from 'file-saver';
import { PDFDocument, rgb } from 'pdf-lib';
import SignatureCanvas from 'react-signature-canvas';
import { Document, Page, pdfjs } from 'react-pdf';
import sample from './BOL_Order.pdf'
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { alignPropType } from 'react-bootstrap/esm/types';
import { useNavigate } from "react-router-dom";
import printJS from 'print-js';


// Initialize PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


interface SignedPDFProps {
  action: any
  fileName: any
  isSigned: any
  showSign : any,
  orderNo : any
}


const SignedPDF: React.FC<SignedPDFProps> = (props: any) => {
  const signatureCanvasRef = useRef<SignatureCanvas>(null);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState<boolean>(false);
  const colors = ['black', 'green', 'red']
  const [open, setOpen] = React.useState(false);
  const [penColor, setPenColor] = useState('black')
  const [imageURL, setImageURL] = useState(null)
  const [rowClicked, setrowClicked] = React.useState(false);
  const [openModel, setOpenModal] = useState(true)
  const [numPages, setNumPages] = useState(4);
  const [pageNumber, setPageNumber] = useState(1);
  const [signature, setSignature] = useState(null);

  const [filePath, setFilePath] = useState("")

  const [blobData, setBlobdata] = useState<any | null>(null);

  const navigate = useNavigate();
  const tokenValue: any = localStorage.getItem("accessToken")
  const apiUrlUploadFile: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/UploadSignedBOLFile"
  const apiUrlGetFile: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/GetSignedMbolFile"

  const onDocumentLoadSuccess = (numPages: any) => {
    setNumPages(numPages);
  };

  const handleClearSignature = () => {
    signatureCanvasRef.current?.clear();
    //setIsSignatureEmpty(true);
    // props.action()
    // navigate("reportBillOfLanding")
  };

  function changePage(offset: any) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function printDoc () {
    //window.open(props.fileName)
    fetch(props.fileName).then(resp => resp.arrayBuffer()).then(resp => {

      // set the blog type to final pdf
      const file = new Blob([resp], {type: 'application/pdf'});

      // process to auto download it
      const fileURL = URL.createObjectURL(file);
      printJS(fileURL);
    });
  }

  function downLoadDoc () {
    fetch(props.fileName).then(resp => resp.arrayBuffer()).then(resp => {

      // set the blog type to final pdf
      const file = new Blob([resp], {type: 'application/pdf'});

      // process to auto download it
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = props.orderNo + ".pdf";
      link.click();
    });
  }

  function nextPage() {
    changePage(1);
  }

  async function GetSignedBOLFile(params: any) {
    return fetch(apiUrlGetFile, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json;',
        Authorization: `Bearer ${tokenValue}`
      },
      body: JSON.stringify(params),
    }).then((data) => data)
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      })
  }

  async function GetSignedFile(file: any) {

    const responseFile = await GetSignedBOLFile(
      {
        "FileName": "signedPDF.pdf"
      })

    if (responseFile) {

      const blob = await responseFile.blob();
      setBlobdata(blob)
      const url = URL.createObjectURL(blob);
      window.URL.revokeObjectURL(url);
      
    }
  }


  async function UploadSignedMbolFile(params: any) {
    const formData = new FormData();
    formData.append('file', params.file);

    return fetch(apiUrlUploadFile, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenValue}`
      },
      body: formData,
    }).then((data) => data.json())
      .catch((error) => {
        console.log("Failed")
        localStorage.clear();
 navigate("/login");
 window.location.reload();
      })
  }

  //async function uploadSignedFile(fileName: string, byteArr: any, blobObj: any) {
  async function uploadSignedFile(file: any) {

    const responseFile = await UploadSignedMbolFile(
      {
        "file": file
      })

    if (responseFile) {
      var msg = responseFile.Message != null ? responseFile.Message : (responseFile.errors != null ? responseFile.errors[""][0] : responseFile.title);
      alert(msg);
      setOpenModal(false)
    
      //call get signedpdf 

      // GetSignedFile("")
    }
  }

  const handleSavePDF = async () => {
    if (!signatureCanvasRef.current || signatureCanvasRef.current.isEmpty()) {
      alert('Please provide a signature.');
      return;
    }

    setIsSignatureEmpty(false);
    // Get the signature as an image
    const signatureImage = signatureCanvasRef.current.toDataURL();

    //get pdf in place of sample 
    var pdfToSign = props.fileName

    // Load the existing PDF document
    const existingPdfBytes = await fetch(pdfToSign).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Add a new page to the document

    //const [page] = pdfDoc.getPages();

    //new code
    // const pdfDoc = await pdfSignature.PDFDocument.load(pdf);

    const pages = await Promise.all(
      Array.from(Array(numPages).keys()).map(async (pageIndex) => {
        const page = await pdfDoc.getPage(pageIndex);
        const pngImage = await pdfDoc.embedPng(signatureImage);
        const pngDims = pngImage.scale(0.3); // Adjust scale if needed
        page.drawImage(pngImage, {
          x: 300, // Adjust x and y coordinates to position the signature
          y: 220,
          width: pngDims.width,
          height: pngDims.height,
        });
      })
    );
    ////new 

    

    const modifiedPdfBytes = await pdfDoc.save();
    const file = new File([modifiedPdfBytes], "signedPDF.pdf", { type: 'application/pdf' });
    // Save the Blob to the disk
    //  const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });

    // Save the Blob to the disk
    //  saveAs(blob, 'signed_document.pdf');
    uploadSignedFile(file)

  };

  const handleSignPDF = async () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpen(false);
    setrowClicked(false)
  };

  return (
    <div>
      {blobData ? (
        <div>
          <img
            src={URL.createObjectURL(blobData)}
            alt="Blob Data"
            style={{ maxWidth: '100%' }}
          />
        </div>
      ) : (
        <div>
          <Document
            file={props.fileName}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            {...Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map(page => <Page pageNumber={page} />)}

          >
            <Page pageNumber={pageNumber} />
          </Document>
          {/* <p>
            Page {pageNumber} of {numPages}
          </p> */}
          <button className='btn btn-primary ml-2' onClick={previousPage} disabled={pageNumber <= 1}>
            Previous
          </button>
          <button className='btn btn-primary ml-2'
            onClick={nextPage}
            disabled={pageNumber == numPages}> next page</button>
          <button className='btn btn-primary ml-2' onClick={printDoc}>
            Print
          </button>
          <button className='btn btn-primary ml-2' onClick={downLoadDoc}>
            Download File
          </button>
          {/* {openModel && (
            <div className='modalContainer'>
              <div className='modal'>
               
                <div >
                  <SignatureCanvas

                    canvasProps={{ width: 250, height: 100, className: 'sigCanvas' }}
                    ref={signatureCanvasRef} />
                  <hr />
                </div>

                <div className='modal__bottom'>
                  <button className='btn btn-primary' onClick={handleClearSignature}>Clear</button>
                  <button className='btn btn-primary ml-2' onClick={() => setOpenModal(false)}>Cancel</button>
                  <button
                    className='btn btn-primary ml-2'
                    onClick={handleSavePDF}>
                    Create
                  </button>
                </div>
              </div>
            </div>
          )}
          {props.showSign && (
            <input className="btn btn-primary ml-2 mt-2 mb-1" style={{ alignItems: 'right' }} value="Sign PDF" type="button" onClick={handleSignPDF}></input>
          )} */}

        </div>
      )}
    </div>

  );
};

export default SignedPDF;
