import React, { useEffect, useState } from 'react';
import { DataGridPremium, GridCallbackDetails, GridColDef, GridRowId, GridRowParams, GridRowsProp, GridToolbar, useGridApiContext } from '@mui/x-data-grid-premium';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, TextField, Typography, styled, useMediaQuery } from '@mui/material';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import DatePick from './DatePick';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs";
import { Height } from '@mui/icons-material';

// import InventoryIcon from "@mui/icons-material/Inventory";
import Out_of_stock from "./../assets/Out_of_stock.svg";
import In_stock from "./../assets/In_stock.svg";
import calendar from "./../assets/calendar.svg";
import { useNavigate } from 'react-router-dom';
import AsnGridRowGroup from './AsnGridRowGroup';
import CloseIcon from '@mui/icons-material/Close';
import GridRowGrouping from './GridRowGrouping';
import OrderNumberDetails from './OrderNumberDetails';



function DetailPanelContent({ row: rowProp }: { row: any }) {
    const apiRef = useGridApiContext();

    const tokenValue: any = localStorage.getItem("accessToken")
    const navigate = useNavigate();
    const getDetailPanelContent = React.useCallback(
        ({ row }: GridRowParams) => <DetailPanelContent row={row} />,
        [],
    );

    // const apiUrlItemsOrder: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/getDashData_itemInventories"
    const apiUrlItemsOrder: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/getDashData_itemInvtTrans_lastN"

    const [open, setOpen] = React.useState(false);

    const currCustomer = localStorage.getItem("currentOwner")
    const currWHS = localStorage.getItem("currentWHS")

    const [openPopUp, setOpenPopUp] = React.useState(false)
    const [rowClicked, setrowClicked] = React.useState(false);
    const [isASN, setIsASN] = React.useState(false);
    const [dateValue, setDateValue] = React.useState("")
    const [asnNo, setasnNumber] = React.useState("")

    const [orderItemsDataDetails, setorderItemsDataDetails] = React.useState([])
    const [orderItemsDataRows, setorderItemsDataRows] = React.useState([])
    const [width, setWidth] = React.useState(() => {
        const dimensions = apiRef.current.getRootDimensions();
        return dimensions.viewportInnerSize.width;
    });

    const handleViewportInnerSizeChange = React.useCallback(() => {
        const dimensions = apiRef.current.getRootDimensions();
        setWidth(dimensions.viewportInnerSize.width);
    }, [apiRef]);


    React.useEffect(() => {
        createMasterData(rowProp.Item, rowProp.dataAsOfDT)
        return apiRef.current.subscribeEvent(
            'viewportInnerSizeChange',
            handleViewportInnerSizeChange,

        );
    }, [apiRef, handleViewportInnerSizeChange]);

    async function getOrderItemsApiData(params: any, paramdate: any) {

        // const datevalue= dailyValue;
        //old one
        // const responseOrdersItems = await getDashData_ordersItems(
        //     {
        //         "custCode": currCustomer,
        //         "whseCode": currWHS,
        //         "item": params,
        //         "asOfDate": paramdate

        //     })
        const responseOrdersItems = await getDashData_ordersItems(
            {
                "custCode": currCustomer,
                "whseCode": currWHS,
                "itemNum": params,
                "asOfDate": paramdate

            })
        if (responseOrdersItems) {
            setorderItemsDataDetails(responseOrdersItems?.Payload)
        }
    }

    async function getDashData_ordersItems(params: any) {
        return fetch(apiUrlItemsOrder, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(params),
        }).then((data) => data.json())
            .catch((error) => {
                console.log("Failed")
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            })
    }

    const createMasterData = (rowprops: any, date: any) => {
        let dataItems: Array<any> = []
        getOrderItemsApiData(rowProp.Item, rowProp.dataAsOfDT)


    }

    const handleClose = () => {
        setOpen(false);
    };


    //old 
    // const columnsItem: GridColDef[] = [
    //     { field: 'Item', headerClassName: "headingbg-color", headerName: 'Item' },
    //     { field: 'Pack', headerClassName: "headingbg-color", headerName: 'Pack' },
    //     { field: 'Lot', headerClassName: "headingbg-color", headerName: 'Lot' },

    //     { field: 'Location', headerClassName: "headingbg-color", headerName: 'Location' },
    //     { field: 'OnHandCases', headerClassName: "headingbg-color", headerName: 'On Hand Cases' },
    //     { field: 'OnHandQuantity', headerClassName: "headingbg-color", headerName: 'On Hand Quantity' },
    //     { field: 'AvailableQuantity', headerClassName: "headingbg-color", headerName: 'Available Quantity' },
    //     { field: 'AllocatedQuantity', headerClassName: "headingbg-color", headerName: 'Allocated Quantity' },
    //     { field: 'PickedQuantity', headerClassName: "headingbg-color", headerName: 'Picked Quantity' },

    //     { field: 'GrossWeight', headerClassName: "headingbg-color", headerName: 'Gross Weight' },
    //     { field: 'NetWeight', headerClassName: "headingbg-color", headerName: 'Net Weight' },
    //     { field: 'TareWeight', headerClassName: "headingbg-color", headerName: 'Tare Weight' },
    //     { field: 'CUBE', headerClassName: "headingbg-color", headerName: 'CUBE' },


    // ];

    //old logic
    // const rowItems: GridRowsProp = orderItemsDataDetails.map((c: any, index) => ({
    //     id: index,
    //     Item: c.Item,
    //     Pack: c.Pack,
    //     Lot: c.Lot,
    //     Location: c.Location,
    //     OnHandCases: c.OnHandCases,
    //     OnHandQuantity: c.OnHandQuantity,
    //     AvailableQuantity: c.AvailableQuantity,
    //     AllocatedQuantity: c.AllocatedQuantity,
    //     PickedQuantity: c.PickedQuantity,
    //     GrossWeight: c.GrossWeight,
    //     NetWeight: c.NetWeight,
    //     TareWeight: c.TareWeight,
    //     CUBE: c.CUBE
    // }))

    const columnsItem: GridColDef[] = [
        { field: 'ItemNum', headerClassName: "headingbg-color", headerName: 'Item Number' },
        { field: 'ItemDescription', headerClassName: "headingbg-color", headerName: 'Item Description', width: 220 },
        { field: 'Balance', headerClassName: "headingbg-color", headerName: 'Balance' },
        { field: 'Quantity', headerClassName: "headingbg-color", headerName: 'Quantity' },
        { field: 'TransactionNum', headerClassName: "headingbg-color", headerName: 'Transaction Number' },
        { field: 'TransactionType', headerClassName: "headingbg-color", headerName: 'Transaction Type' },
        { field: 'Date', headerClassName: "headingbg-color", headerName: 'Date' },
        { field: 'GrossWeight', headerClassName: "headingbg-color", headerName: 'Gross Weight' },
        { field: 'NetWeight', headerClassName: "headingbg-color", headerName: 'Net Weight' },
        { field: 'TareWeight', headerClassName: "headingbg-color", headerName: 'Tare Weight' },
    ];

    const rowItems: GridRowsProp = orderItemsDataDetails.map((c: any, index) => ({
        id: index,
        ItemNum: c.ItemNum,
        ItemDescription: c.ItemDescription,
        Balance: c.Balance,
        Quantity: c.Quantity,
        TransactionNum: c.TransactionNum,
        TransactionType: c.TransactionType,
        Date: c.Date,
        GrossWeight: c.GrossWeight,
        NetWeight: c.NetWeight,
        TareWeight: c.TareWeight
    }))

    const handlerRowClick = (rowprops: any, cellprops: any) => {

        const rowType = rowprops.row.TransactionType
        const rowDate = rowprops.row.Date
        const asnNum = rowprops.row.TransactionNum
        if (rowType == "ASN") {
            setIsASN(true)
            setDateValue(rowDate)
            setOpenPopUp(true);
            setrowClicked(true)
            setasnNumber(asnNum)
        }
        else {
            setIsASN(false)
            setDateValue(rowDate)
            setOpenPopUp(true);
            setrowClicked(true)
            setasnNumber(asnNum)
        }
    }

    const handleCloseDetails = () => {
        setOpenPopUp(false);
        setrowClicked(false)
    };

    return (
        <><Stack
            sx={{
                py: 2,
                height: '100%',
                boxSizing: 'border-box',
                position: 'sticky',
                left: 0,
                width,
            }}
            direction="column"
        >
            <Paper sx={{ flex: 1, mx: 'auto', width: '90%', height: '1000px', p: 1 }}>
                <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                    <Typography className='colordivSumHead' fontWeight="bold" variant="h6">{`Item No - ${rowProp.Item}`}</Typography>
                    <Box sx={{ width: '100%', height: 300 }}>
                        <DataGridPremium
                            density="compact"
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                }
                            }}
                            rowSelection={true}
                            columns={columnsItem}
                            rows={rowItems}
                             onRowClick={handlerRowClick}
                            sx={{ flex: 1 }}
                            hideFooter />
                    </Box>
                </Stack>
            </Paper>
            <Box
                sx={{ width: '100%', height: '200px' }}>

                <Dialog
                    open={openPopUp}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    style={{
                        height: '100%', width: '100%', alignItems: 'center'
                    }}
                >
                    <DialogTitle className='colordivSumHead font-bold' id="form-dialog-title">Bill To / Ship To Details: </DialogTitle>
                    <DialogContent>

                        <Box sx={{ width: 1100, height: 550 }}>
                            {isASN ? (

                                <AsnGridRowGroup
                                    status="all"
                                    fromDate={dateValue}
                                    toDate={dateValue}
                                    asnNumber={asnNo}
                                ></AsnGridRowGroup>

                            ) : (
                                <OrderNumberDetails
                                    status="all"
                                    fromDate={dateValue}
                                    toDate={dateValue}
                                    orderNumber={asnNo}
                                ></OrderNumberDetails>
                            )

                            }

                        </Box>

                    </DialogContent>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDetails}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Dialog>
            </Box>
        </Stack>         </>
    );
}


const DataGridBalance: React.FC = (props) => {
    const [status, setStatus] = React.useState('All');
    const [statusData, setStatusData] = React.useState([])
    const navigate = useNavigate();

    const [balanceData, setBalanceData] = React.useState([])

    const [outOfStockItem, setoutOfStockItem] = React.useState(0);
    const [notoutOfStockItem, setnotoutOfStockItem] = React.useState(0);


    const currCustomer = localStorage.getItem("currentOwner")
    const currWHS = localStorage.getItem("currentWHS")

    const tokenValue: any = localStorage.getItem("accessToken")
    const apiUrlBal: any = "https://igdstg.igdistribution.net/igdcpapi/api/user/getDashData_inventoryBal"


    async function getBalApiData(dateDaoily: any) {
        const responseBal = await getDashData_balance(
            {
                "item": "all",
                "lot": "all",
                "loc": "all",
                "asOfDate": dateDaoily,
                "custCode": currCustomer,
                "whseCode": currWHS
            })
        if (responseBal) {

            setBalanceData(responseBal.Payload?.Table1)
            //  const rowsFilterdNotZeroQnty = responseBal.Payload?.filter(((c: any) => c.OnHandQuantity > 0))

            setoutOfStockItem(responseBal.Payload?.Table[0].outOfStockItems)

            //  const rowsFilterdZeroQnty = responseBal.Payload?.filter(((c: any) => c.OnHandQuantity === 0))
            setnotoutOfStockItem(responseBal.Payload?.Table[0].itemsInStock)

        }
    }

    async function getDashData_balance(params: any) {
        return fetch(apiUrlBal, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;',
                Authorization: `Bearer ${tokenValue}`
            },
            body: JSON.stringify(params),
        }).then((data) => data.json())
            .catch((error) => {

                console.log("Failed")
                localStorage.clear();
                navigate("/login");
                window.location.reload();
            })
    }

    useEffect(() => {
        try {
            getBalApiData(dailyValue)
        }
        catch (e) {
            //log error
        }
    },
        []);

    const columns = [

        { field: 'Item', headerClassName: "headingbg-color", headerName: 'Item' },
        { field: 'Pack', headerClassName: "headingbg-color", headerName: 'Pack' },
        { field: 'Description', headerClassName: "headingbg-color", headerName: 'Description', width: 200 },
        { field: 'OnHandCases', headerClassName: "headingbg-color", headerName: 'On Hand QTY', width: 100 },
        { field: 'OnHandQuantity', headerClassName: "headingbg-color", headerName: 'On Hand PCS' },
        
        // { field: 'Lot', headerClassName: "headingbg-color", headerName: 'Lot' },
        // { field: 'Location', headerClassName: "headingbg-color", headerName: 'Location' },

        // { field: 'LPN', headerClassName: "headingbg-color", headerName: 'LPN' },
        
        { field: 'InTransitQty', headerClassName: "headingbg-color", headerName: 'In Transit Qty' },
        { field: 'InTransitPcs', headerClassName: "headingbg-color", headerName: 'In Transit Pcs' },
        { field: 'AvailableQty', headerClassName: "headingbg-color", headerName: 'Available Qty' },
        { field: 'AvailablePcs', headerClassName: "headingbg-color", headerName: 'Available Pcs' },
        { field: 'AllocatedQty', headerClassName: "headingbg-color", headerName: 'Allocated Qty' },
        { field: 'AllocatedPcs', headerClassName: "headingbg-color", headerName: 'Allocated Pcs' },
        { field: 'PickedQty', headerClassName: "headingbg-color", headerName: 'Picked Qty' },
        { field: 'PickedPcs', headerClassName: "headingbg-color", headerName: 'Picked Pcs' },
        { field: 'CUBE', headerClassName: "headingbg-color", headerName: 'Item Cuft' },
        { field: 'TotalCUBE', headerClassName: "headingbg-color", headerName: 'Total Cuft' },

        // { field: 'Status', headerClassName: "headingbg-color", headerName: 'Status' },
        { field: 'GrossWeight', headerClassName: "headingbg-color", headerName: 'Gross Weight' },
        // { field: 'NetWeight', headerClassName: "headingbg-color", headerName: 'Net Weight' },
        // { field: 'TareWeight', headerClassName: "headingbg-color", headerName: 'Tare Weight' },
        // { field: 'dataAsOfDT', headerClassName: "headingbg-color", headerName: 'Date', width: 150 },



    ]

    const getDetailPanelContent = React.useCallback(
        ({ row }: GridRowParams) => <DetailPanelContent row={row} />,
        [],
    );
    const getDetailPanelHeight = React.useCallback(() => 200, []);


    const [dailyValue, setDailyValue] =
        React.useState<Dayjs | null>(dayjs());


    const rows: GridRowsProp = balanceData.map((c: any, index) => ({
        id: index,
        Item: c.Item,
        Pack: c.Pack,
        Description: c.Description,
        OnHandCases: c.OnHandCases,
        Lot: c.Lot,
        Location: c.Location,
        LPN: c.LPN,
        OnHandQuantity: c.OnHandQuantity,
        InTransitQty: c.InTransitQty,
        AvailableQty: c.AvailableQty,
        AllocatedQty: c.AllocatedQty,
        PickedQty: c.PickedQty,
        Status: c.Status,
        GrossWeight: c.GrossWeight,
        NetWeight: c.NetWeight,
        TareWeight: c.TareWeight,
        dataAsOfDT: c.dataAsOfDT,
        InTransitPcs: c.InTransitPcs,
        AvailablePcs: c.AvailablePcs,
        AllocatedPcs: c.AllocatedPcs,
        PickedPcs: c.PickedPcs,
        CUBE: c.CUBE,
        TotalCUBE: c.TotalCUBE


    }));
    const [selectedFrom, setselectedFrom] = React.useState('');
    const [selectedTo, setselectedTo] = React.useState('');
    const [state, setState] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });

    const isMobile = useMediaQuery("(max-width: 450px)");
    const isTablet = useMediaQuery("(min-width:451px) and (max-width:850px)");
    const { start, end } = state;

    const label =
        start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY');
    const handleCallback = (start: any, end: any) => {
        setState({ start, end });
        const labelStart =
            start.format('YYYY-MM-DD')
        const labelEnd =
            end.format('YYYY-MM-DD')

        setselectedFrom(labelStart)
        setselectedTo(labelEnd)
        const dailyStatus = { fromDate: labelStart, toDate: labelEnd }
        // to do getBalApiData
        // getOrdersApiData(dailyStatus)

    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.primary,
    }))


    const handleDailyChange = async (event: any) => {

        // setyearValue(event.target.value);
        if (event) {
            const statusValue = event.$d
            const dateDaoily = statusValue.toISOString().substring(0, 10)

            const dailyStatus = { fromDate: dateDaoily, toDate: dateDaoily }
            // setDailyValue(dateDaoily)
            getBalApiData(dateDaoily);
        }
    };

    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<
        GridRowId[]
    >([]);

    const handleDetailPanelExpandedRowIdsChange = React.useCallback(
        (newIds: GridRowId[], details: GridCallbackDetails) => {

            setDetailPanelExpandedRowIds(
                newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
            );

        },
        [],
    );


    return (

        <div className=" p-4" style={{ width: "100%" }}>

            <div className=" mb-8 text-center pageHeading">Stock Balance Details</div>

            <Box>

                <Grid container spacing={1} gap={2} justifyContent='center'>

                    <Grid

                        item

                        xs={isMobile ? 12 : isTablet ? 3.5 : 2}

                        style={{

                            border: "1px solid #bdb9bc",

                            padding: '12px',

                            borderRadius: "4px",

                            justifyContent: isMobile ? "center" : "flex-start",

                            display: "flex",

                            alignItems: "center",

                        }}

                    >

                        <img

                            src={calendar}

                            style={{ width: "30px", marginRight: "20px" }}

                        ></img>

                        <LocalizationProvider

                            dateAdapter={AdapterDayjs} >

                            <DatePicker

                                label=""

                                format='YYYY-MM-DD'

                                value={dailyValue}

                                className='ml-8'

                                onAccept={(newValue) => setDailyValue(newValue)}

                                onChange={(newDailyValue) => handleDailyChange(newDailyValue)}



                                slotProps={{ textField: { size: 'small' } }}

                            />

                        </LocalizationProvider>

                    </Grid>

                    <Grid

                        item

                        xs={isMobile ? 12 : isTablet ? 3.5 : 2}

                        style={{

                            border: "1px solid #bdb9bc",

                            padding: "12px",

                            borderRadius: "4px",

                            display: "flex",

                            alignItems: "center",

                        }}

                    >

                        <img src={Out_of_stock} style={{ width: "30px" }}></img>

                        <div style={{ display: "flex", flexDirection: "column" }}>

                            <Typography

                                style={{

                                    fontSize: "15px",

                                    color: "#2a358c",

                                    marginLeft: "20px",

                                }}

                            >

                                Out of stock items

                            </Typography>

                            <Typography

                                style={{

                                    fontSize: "19px",

                                    fontWeight: 600,

                                    marginLeft: "25px",

                                }}

                            >

                                {outOfStockItem}

                            </Typography>

                        </div>

                    </Grid>

                    <Grid

                        item

                        xs={isMobile ? 12 : isTablet ? 3.5 : 2}

                        style={{

                            border: "1px solid #bdb9bc",

                            padding: "12px",

                            borderRadius: "4px",

                            display: "flex",

                            alignItems: "center",

                        }}

                    >

                        <img src={In_stock} style={{ width: "30px" }}></img>

                        <div style={{ display: "flex", flexDirection: "column" }}>

                            <Typography

                                style={{

                                    fontSize: "15px",

                                    color: "#2a358c",

                                    marginLeft: "20px",

                                }}

                            >

                                Items in stock

                            </Typography>

                            <Typography

                                style={{

                                    fontSize: "19px",

                                    fontWeight: 600,

                                    marginLeft: "25px",

                                }}

                            >

                                {notoutOfStockItem}

                            </Typography>

                        </div>

                    </Grid>

                </Grid>

            </Box>



            <p className="mt-16 ml-2  mb-2 colordivSumHead">

                Stock Balance details :

            </p>

            <Box sx={{ width: "100%", height: 560 }}>

                <DataGridPremium
                    getDetailPanelHeight={getDetailPanelHeight}
                    getDetailPanelContent={getDetailPanelContent}
                    onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                    detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                    sx={{
                        '& .MuiDataGrid-detailPanel': {
                            overflow: 'visible',
                        },
                    }}

                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        }
                    }}

                    columns={columns}

                    rows={rows}

                ></DataGridPremium>

            </Box>

        </div>

    )
}

export default DataGridBalance