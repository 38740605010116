import React, { useEffect, useState } from "react";
import {
  DataGridPremium,
  GridActionsCellItem,
  GridCellSelectionModel,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridRowsProp,
  GridToolbar,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { styled } from "@mui/material/styles";
import { FileDownload } from "@mui/icons-material";
import { isAction } from "@reduxjs/toolkit";

import CloseIcon from "@mui/icons-material/Close";
import { debug } from "console";
import { useNavigate } from "react-router-dom";
import SignedPDF from "./SignedPDF";

export default function BillOfLandingReport() {
  const [orderNo, setOrderNo] = React.useState("");
  const [statusData, setStatusData] = React.useState([]);

  const [isSigned, setIsSigned] = React.useState(0);
  const [signedFileName, setsignedFileName] = React.useState("");

  const [open, setOpen] = React.useState(false);

  const [rowClicked, setrowClicked] = React.useState(false);

  const toggle = () => setOpen(!open);

  const [gridValidRowModel, setGridValidRowModel] =
    React.useState<GridValidRowModel>({});

  let cellClicked = false;
  const [balanceData, setBalanceData] = React.useState([]);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const currCustomer = localStorage.getItem("currentOwner");
  const currWHS = localStorage.getItem("currentWHS");

  const [orderDataBillShipTo, setOrderDataBillShipTo] = React.useState([]);
  const tokenValue: any = localStorage.getItem("accessToken");
  const apiUrlBal: any =
    "https://igdstg.igdistribution.net/igdcpapi/api/user/getRprt_billOfLadingDtls";
  const apiUrlGetBol: any =
    "https://igdstg.igdistribution.net/igdcpapi/api/user/GetBOLfileToSign";
  const apiUrlBillToShipTo: any =
    "https://igdstg.igdistribution.net/igdcpapi/api/user/GetOrderBillToShipToDtls";

  const navigate = useNavigate();
  const apiRef = useGridApiRef();
  const [cellSelectionModel, setCellSelectionModel] =
    React.useState<GridCellSelectionModel>({});

  const [state, setState] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });
  const { start, end } = state;
  const handleCallback = (start: any, end: any) => {
    setState({ start, end });
    const labelStart = start.format("YYYY-MM-DD");
    const labelEnd = end.format("YYYY-MM-DD");

    getBalApiData(labelStart, labelEnd);
  };
  const label =
    start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");

  async function getBalApiData(frmDate: string, toDate: string) {
    const responseBal = await getDashData_balance({
      custCode: currCustomer,
      whseCode: currWHS,
      carrier: "all",
      status: "all",
    });
    if (responseBal) {
      setBalanceData(responseBal.Payload);
    }
  }

  async function getDashData_balance(params: any) {
    return fetch(apiUrlBal, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: `Bearer ${tokenValue}`,
      },
      body: JSON.stringify(params),
    })
      .then((data) => data.json())
      .catch((error) => {
        console.log("Failed");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      });
  }

  async function getBOlApiData(bol: string) {
    const responseBal = await getDashData_BOL({
      custCode: currCustomer,
      whseCode: currWHS,
      bolNumber: bol,
      mBolNumber: "",
    });
    if (responseBal) {
      setsignedFileName(
        "https://igdstg.igdistribution.net/igdcpapi/" + responseBal.Payload
      );
      setOpen(true);
    }
  }

  async function getDashData_BOL(params: any) {
    return fetch(apiUrlGetBol, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: `Bearer ${tokenValue}`,
      },
      body: JSON.stringify(params),
    })
      .then((data) => data.json())
      .catch((error) => {
        console.log("Failed");
        localStorage.clear();
        navigate("/login");
        window.location.reload();
      });
  }

  useEffect(() => {
    try {
      const startdate = start.format("YYYY-MM-DD");
      const endDate = end.format("YYYY-MM-DD");

      getBalApiData(startdate, endDate);
    } catch (e) {
      //log error
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: "OrderNumber",
      width: 120,
      headerClassName: "headingbg-color",
      headerName: "IGD Order",
    },
    {
      field: "ExternalOrderNo",
      width: 120,
      headerClassName: "headingbg-color",
      headerName: "Customer Order",
    },
    {
      field: "PurchaseOrder",
      width: 120,
      headerClassName: "headingbg-color",
      headerName: "Purchased Order",
    },
    {
      field: "ActualShipDate",
      width: 120,
      headerClassName: "headingbg-color",
      headerName: "Actual Ship Date",
    },
    {
      field: "BLNumber",
      width: 150,
      renderCell: (params) => {
        if (params.row.signedBOLFile || params.row.sourceBOLFile) {
          return (
            <Link href="#">{params.value && params.value.toString()}</Link>
          );
        } else {
          return params.value && params.value.toString();
        }
      },
      headerClassName: "headingbg-color",
      headerName: "BOL Number",
    },
    {
      field: "BLStatus",
      headerClassName: "headingbg-color",
      headerName: "BOL Status",
    },
    {
      field: "OrderType",
      headerClassName: "headingbg-color",
      headerName: "Order Type",
    },

    {
      field: "BillTo",
      renderCell: (params) => <Link href="#">{params.value!.toString()}</Link>,
      headerClassName: "headingbg-color",
      headerName: "Bill To",
    },
    {
      field: "ShipTo",
      renderCell: (params) => <Link href="#">{params.value!.toString()}</Link>,
      headerClassName: "headingbg-color",
      headerName: "Ship To",
    },

    {
      field: "CarrierCode",
      headerClassName: "headingbg-color",
      headerName: "SCAC",
    },
    {
      field: "CarrierName",
      headerClassName: "headingbg-color",
      headerName: "Carrier Name",
    },
    {
      field: "FreightPaymentType",
      headerClassName: "headingbg-color",
      headerName: "Freight Payment Type",
    },

    {
      field: "Tracking_PROnumber",
      headerClassName: "headingbg-color",
      headerName: "Tracking/PRO",
    },
    {
      field: "SealNumber",
      width: 250,
      headerClassName: "headingbg-color",
      headerName: "Seal Number",
    },
    {
      field: "isBOLSignedRender",
      width: 50,
      headerClassName: "headingbg-color",
      headerName: "BOL Signed",
    },
    // { field: 'signedBOLFile', width: 200, headerClassName: "headingbg-color", headerName: 'Signed BOL File' },
    // { field: 'sourceBOLFile', width: 200, headerClassName: "headingbg-color", headerName: 'source BOL File' }
  ];

  const rows: GridRowsProp = balanceData.map((c: any, index) => ({
    id: index,
    OrderNumber: c.OrderNumber,
    BLNumber: c.BLNumber,
    BLStatus: c.BLStatus,
    OrderType: c.OrderType,
    BillTo: c.BillTo,
    ShipTo: c.ShipTo,
    CarrierCode: c.CarrierCode,
    CarrierName: c.CarrierName,
    FreightPaymentType: c.FreightPaymentType,
    Tracking_PROnumber: c.Tracking_PROnumber,
    SealNumber: c.SealNumber,
    isBOLSigned: c.isBOLSigned,
    isBOLSignedRender: c.isBOLSigned === 0 ? "No" : "Yes",
    signedBOLFile: c.signedBOLFile,
    sourceBOLFile: c.sourceBOLFile,
    ExternalOrderNo: c.ExternalOrderNo,
    PurchaseOrder: c.PurchaseOrder,
    ActualShipDate: c.ActualShipDate,
  }));

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const handleClose = () => {
    setOpen(false);
    setrowClicked(false);
  };

  const handleCloseDetails = () => {
    setOpenPopUp(false);
    setrowClicked(false);
  };

  const handlerRowClick = (rowprops: any, cellprops: any) => {
    if (rowClicked) {
      setrowClicked(true);
      setOpenPopUp(false);
    }
  };

  async function getBillToShipToDetails(params: any) {
    const responseOrders = await getBillToShipTo_Details({
      custCode: currCustomer,
      whseCode: currWHS,
      orderNo: params,
    });

    if (responseOrders) {
      setOrderDataBillShipTo(responseOrders.Payload);
    }
  }

  async function getBillToShipTo_Details(params: any) {
    return fetch(apiUrlBillToShipTo, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        Authorization: `Bearer ${tokenValue}`,
      },
      body: JSON.stringify(params),
    })
      .then((data) => data.json())
      .catch((error) => {
        console.log("Failed");
        navigate("/login");
      });
  }

  const handleCellSelectionModelChange = React.useCallback(
    (newModel: GridCellSelectionModel) => {
      Object.entries(newModel).forEach(([id, fields]) => {
        if (fields.BillTo || fields.ShipTo) {
          const updatedRow = apiRef.current.getRow(id);
          getBillToShipToDetails(updatedRow.OrderNumber);
          setOpenPopUp(true);
          cellClicked = true;
          setrowClicked(false);
          setGridValidRowModel(updatedRow);
        } else if (fields.BLNumber) {
          const updatedRow = apiRef.current.getRow(id);
          setOrderNo(updatedRow.OrderNumber);
          setIsSigned(updatedRow.isBOLSigned);
          const filepath = "https://igdstg.igdistribution.net/igdcpapi/";
          setsignedFileName(filepath + updatedRow.signedBOLFile);
          debugger;
          if (updatedRow.isBOLSigned) {
            setOpen(true);
            // window.open(filepath + rowprops.row.signedBOLFile, "_blank");
          } else {
            if (updatedRow.sourceBOLFile) {
              setsignedFileName(filepath + updatedRow.sourceBOLFile);
              setOpen(true);
            }

            //call get api
            //   getBOlApiData(rowprops.row.BLNumber)
          }
          setGridValidRowModel(updatedRow);
        } else {
          setrowClicked(true);
          cellClicked = false;
        }
      });

      // setCellSelectionModel(newModel);
    },
    []
  );

  return (
    <div className="ml-2">
      <div className="mt-8 mb-6 text-center pageHeading">
        BOL Report details{" "}
      </div>
      <div className="pb-2" style={{ width: "30%" }}></div>
      {/* <p className='ml-2 mb-2'>
                    <h6 className='colordivSumHead'> BOL details:</h6>
                </p> */}
      <Box sx={{ width: "100%", height: 650, padding: "0 5%" }}>
        <DataGridPremium
          slots={{ toolbar: GridToolbar }}
          apiRef={apiRef}
          cellSelectionModel={cellSelectionModel}
          onCellSelectionModelChange={handleCellSelectionModelChange}
          cellSelection={true}
          onRowClick={handlerRowClick}
          columns={columns}
          rows={rows}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        ></DataGridPremium>
      </Box>
      {open && (
        // <Box sx={{ width: '100%', height: '800px' }}>

        <Dialog
          id="signDialog"
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          style={{
            height: "100%",
            width: "100%",
            alignItems: "left",
          }}
        >
          <DialogTitle
            className="colordivSumHead font-bold"
            id="form-dialog-title"
          >
            Order No: {orderNo}
          </DialogTitle>
          <DialogContent>
            <SignedPDF
              fileName={signedFileName}
              action={handleClose}
              isSigned={isSigned}
              showSign={false}
              orderNo={orderNo}
            ></SignedPDF>
          </DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>
        // </Box>
      )}

      <Box sx={{ width: "100%", height: 500 }}>
        <Dialog
          open={openPopUp}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          style={{
            height: "100%",
            width: "100%",
            alignItems: "center",
          }}
        >
          <DialogTitle
            className="colordivSumHead font-bold"
            id="form-dialog-title"
          >
            Bill To / Ship To Details:{" "}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ width: 800, height: 550 }}>
              {orderDataBillShipTo?.map((s: any) => {
                return (
                  <>
                    <table style={{ height: "60%", width: "100%" }}>
                      <th className="colordivSumHead font-bold mr-8">
                        Bill To Details :
                      </th>
                      <tr>
                        <td className="font-bold">Order Number</td>
                        <td>{s.OrderNumber}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">BillTo Name</td>
                        <td>{s.BillTo_Name}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">BillTo Address</td>
                        <td>{s.BillTo_Address}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">BillTo City</td>
                        <td>{s.BillTo_City}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">BillTo State</td>
                        <td>{s.BillTo_State}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">BillTo Country</td>
                        <td>{s.BillTo_Country}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">BillTo ZipCode</td>
                        <td>{s.BillTo_ZipCode}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">BillTo PhoneNo</td>
                        <td>{s.BillTo_PhoneNo}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">BillTo Contact</td>
                        <td>{s.BillTo_Contact}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">BillTo VAT</td>
                        <td>{s.BillTo_VAT}</td>
                      </tr>
                      <div></div>
                      <div></div>
                      <div></div>
                    </table>
                    <table style={{ height: "60%", width: "100%" }}>
                      <th className="colordivSumHead font-bold mr-8">
                        Ship To Details :
                      </th>

                      <tr>
                        <td className="font-bold">Order Number</td>
                        <td>{s.OrderNumber}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">ShipTo Name</td>
                        <td>{s.ShipTo_Name}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">ShipTo Address</td>
                        <td>{s.ShipTo_Address}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">ShipTo City</td>
                        <td>{s.ShipTo_City}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">ShipTo State</td>
                        <td>{s.ShipTo_State}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">ShipTo Country</td>
                        <td>{s.ShipTo_Country}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">ShipTo ZipCode</td>
                        <td>{s.ShipTo_ZipCode}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">ShipTo PhoneNo</td>
                        <td>{s.ShipTo_PhoneNo}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">ShipTo Contact</td>
                        <td>{s.ShipTo_Contact}</td>
                      </tr>
                      <tr>
                        <td className="font-bold">ShipTo Fax</td>
                        <td>{s.ShipTo_Fax}</td>
                      </tr>

                      <tr>
                        <td className="font-bold">ShipTo VAT</td>
                        <td>{s.ShipTo_VAT}</td>
                        <td></td>
                      </tr>
                    </table>
                  </>
                );
              })}
            </Box>
          </DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleCloseDetails}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>
      </Box>
    </div>
  );
}
